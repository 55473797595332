<template>
    <div class="container">
        <div class="dean-eds-form mt-4">
            <!-- Cert Info Modal -->
            <div class="modal fade" id="certInfoModal" tabindex="-1" aria-labelledby="certInfoModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="certInfoModalLabel">Информация о подписавших</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">

                                <div v-for="(item, index) in certInfos" :key="index">

                                    <p>Шаблон сертификата : {{ item.certTemplate }}</p>

                                    <div v-if="item.bin == ''">
                                        <p>ИИН: {{ item.serialNumber }}</p>
                                        <p>ФИО: {{ item.commonName }}&nbsp;{{ item.givenName }}</p>
                                    </div>
                                    <div v-else>
                                        <p>БИН: {{ item.bin }}</p>
                                        <p>Название организации : {{ item.orgName }}</p>
                                        <p>Роль подписанта : {{ item.roleName }}</p>
                                    </div>

                                    <p>Серийный номер сертификата: {{ item.certificateSerialNumber }}</p>

                                    <p>Срок действия сертификата: {{ item.notBefore }} - {{ item.notAfter }}</p>

                                    <p>Дата подписания : {{ item.time }}</p>

                                    <hr>

                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->

            <!-- Delete File Modal -->
            <div class="modal fade" id="deleteFileModal" tabindex="-1" aria-labelledby="deleteFileModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteFileModalLabel">Расторжение договора</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно уверены что хотите расторгнуть договор и удалить файл?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    @click="deleteSignatureFile(deleteFileId)">
                                Удалить
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Отменить
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->


            <!-- Cancel File Modal -->
            <div class="modal fade" id="cancelFileModal" tabindex="-1" aria-labelledby="cancelFileModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cancelFileModalLabel">Отменить</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно уверены что хотите отменить?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    @click="updateDeleteFileStatus(deleteFileId)">
                                Да
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Нет
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->

            <div class="row mb-3">
                <div class="col-md-3">
                    <label for="sort">Сортировка по дате</label>
                    <select class="form-control form-select" id="sort" @input="changeSortStatus">
                        <option v-for="(item, index) in sortDateValues" :key="index"
                                :value="item.status">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-3">
                    <label for="filterSignedStatus">Фильтрация по статусу</label>
                    <select class="form-control form-select" id="filterSignedStatus"
                            @input="changeFilterSignedStatus">
                        <option v-for="(item, index) in filterSignedStatuses"
                                :value="item.status"
                                :key="index">{{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div v-if="signatureFilesInfos.length">


                <div class="row">
                    <div class="col-12 ml-3 mt-4">
                        <h2 class="d-block" style="font-size: 1.5rem; font-weight: bold; text-align: center">Список
                            договоров</h2>
                    </div>
                </div>

                <div class="table-responsive-md mt-4">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Количество подписавших</th>
                            <th scope="col">Название файла</th>
                            <th scope="col">Подписать</th>
                            <th scope="col">Посмотреть информацию</th>
                            <th scope="col">Удалить</th>
                            <th scope="col">Отменить</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in signatureFilesInfos" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ item.count }}</td>
                            <td>
                                <div v-if="item.file">
                                    <a :href="'https://signature.uib.kz/'+item.file.file_url">{{
                                        item.file.file_name
                                        }}</a>
                                </div>
                                <div v-else>Файла нету</div>
                            </td>
                            <td>
                                <button class="btn btn-success" type="button" @click="signFile(item.file_id)"
                                        :disabled="signatureFileIndex==index"
                                        :class="{ disabled: (signatureFilesInfos[index].count != 2) }">
                                    {{ signatureFileIndex == index ? 'Подписывается' : 'Подписать' }}
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                                        data-bs-target="#certInfoModal" @click="openInfoModal(item.file_id)"
                                        :class="{ disabled: (signatureFilesInfos[index].count == 0) }">
                                    Посмотреть информация
                                </button>
                            </td>
                            <!-- v-if="signatureFilesInfos[index].file.delete_confirmation == 1" -->
                            <template v-if="signatureFilesInfos[index].file">
                                <td v-if="parseInt(signatureFilesInfos[index].file.delete_confirmation) == 1">
                                    <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                            data-bs-target="#deleteFileModal"
                                            @click="openDeleteFileModal(item.file_id)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </button>
                                </td>
                                <td v-else>
                                    <p>Запроса нет</p>
                                </td>
                                <td v-if="parseInt(signatureFilesInfos[index].file.delete_confirmation) == 1">
                                    <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                                            data-bs-target="#cancelFileModal"
                                            @click="openCancelFileModal(item.file_id)">
                                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                    </button>
                                </td>
                                <td v-else>
                                    <p>Запроса нет</p>
                                </td>
                            </template>
                            <template v-else>
                                <td>
                                    <p>Запроса нет</p>
                                </td>
                                <td>
                                    <p>Запроса нет</p>
                                </td>
                            </template>

                        </tr>
                        </tbody>
                    </table>
                </div>
                <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">

                    <Paginate
                            v-model="page"
                            :page-count="pageCount"
                            :click-handler="paginateHandler"
                            :prev-text="'Предыдущий'"
                            :next-text="'Следующий'"
                            :container-class="'pagination'"
                            :page-class="'page-item'">
                    </Paginate>
                </nav>

            </div>
            <h3 class="d-flex justify-content-center" v-else>
                Договоров пока нет
            </h3>


            <!--            <div>-->
            <!--                <nav class="d-flex justify-content-center" aria-label="Page navigation example">-->
            <!--                    <ul class="pagination">-->
            <!--                        <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"-->
            <!--                                                                                             aria-hidden="true"></i></a>-->
            <!--                        </li>-->
            <!--                        <li class="page-item" v-for="item in pageCount" :key="item" :class="{active: item==page}">-->
            <!--                            <a class="page-link" href="#"-->
            <!--                               @click="movePageNumber(item)">{{ item }}</a>-->
            <!--                        </li>-->
            <!--                        <li class="page-item"><a class="page-link" href="#" @click="next"><i class="fa fa-arrow-right"-->
            <!--                                                                                             aria-hidden="true"></i></a>-->
            <!--                        </li>-->
            <!--                    </ul>-->
            <!--                </nav>-->
            <!--            </div>-->

        </div>
    </div>


</template>

<script>

    import Paginate from '../common/Paginate.vue'
    import httpClient from "../../services/http.service";
    import axios from "axios";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: "DeanSignature",
        components: {
            Paginate
        },

        data() {
            return {
                signatureFilesInfos: [],
                certInfos: [],
                pageCount: 0,
                deleteFileId: '',
                loading: false,
                page: +this.$route.query.page || 1,
                signatureFileIndex: -1,
                dateStatus: (+this.$route.query.date === 0 || +this.$route.query.date) ? +this.$route.query.date : 2,
                filterSignedStatus: (+this.$route.query.status === 0 || +this.$route.query.status) ? +this.$route.query.status : 2
            }
        },
        computed: {
            sortDateValues() {
                return [
                    {
                        status: 2,
                        name: 'Без сортировки'
                    },
                    {
                        status: 0,
                        name: 'По убыванию'
                    },
                    {
                        status: 1,
                        name: 'По возрастанию'
                    }
                ]
            },
            filterSignedStatuses() {
                return [
                    {
                        status: 2,
                        name: 'Без фильтрации'
                    },
                    {
                        status: 0,
                        name: 'Не подписанные'
                    },
                    {
                        status: 1,
                        name: 'Подписанные'
                    }
                ]
            }
        },

        methods: {
            async changeSortStatus(e) {
                this.dateStatus = e.target.value
                await this.changePage()
            },
            async changeFilterSignedStatus(e) {
                this.filterSignedStatus = e.target.value
                await this.changePage()
            },
            async paginateHandler(page) {
                this.page = page
                await this.changePage()
            },
            async changePage() {
                this.$router.push(`${this.$route.path}?page=${this.page}&status=${this.filterSignedStatus}&date=${this.dateStatus}`)
                this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
            },
            async openInfoModal(file_id) {
                this.certInfos = await this.GET_INFO_CERT(file_id);
            },
            async openDeleteFileModal(file_id) {
                this.deleteFileId = file_id;
                console.log(this.deleteFileId, "DELETE FILE ID")
            },
            async openCancelFileModal(file_id) {
                this.deleteFileId = file_id;
            },
            async updateDeleteFileStatus(file_id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/signature-files/delete-confirmation-cancel' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&file_id=' + file_id);
                    if (status === 200) {
                        console.log(data, "UPDATE DELETE FILE STATUS")
                        this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus);
                        return data
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async deleteSignatureFile(file_id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.delete('questionnaire/signature-files/delete-files' + '?access-token=' + getCookie('ACCESS_TOKEN') + "&file_id=" + file_id);
                    if (status === 200) {
                        console.log(data, "DELETE FILE")
                        let index = this.signatureFilesInfos.findIndex(i => i.file_id == file_id)
                        this.signatureFilesInfos.splice(index, 1)
                        this.$message({title: 'Удаление', text: 'Файл успешно удален'})

                        return data
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    this.$error({title: 'Ошибка при удалении', text: 'Произошла ошибка при удалении'})
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async signFile(id) {
                this.signatureFileIndex = this.signatureFilesInfos.findIndex(i => i.file_id == id)
                try {
                    await this.POST_SIGNATURE_FILES(id)
                    this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
                } catch (e) {
                    console.log('Произошла ошибка при подписании')
                    this.$error({title: 'Ошибка при подписании', text: 'Произошла ошибка при подписании'})

                }
                this.signatureFileIndex = -1;
            },
            async POST_SIGNATURE_FILES(id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/signature-files/response-signature' + '?access-token=' + getCookie('ACCESS_TOKEN'), {'file_id': id});
                    if (status === 200) {
                        console.log(data, "POST")
                        this.countSignatureFiles = await this.GET_COUNT_SIGNATURE_FILES()
                        this.certInfos = await this.GET_INFO_CERT(this.signatureFilesInfos[0].file_id);
                        return data
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_SIGNATURE_FILES(page, filterSignedStatus, dateStatus) {
                try {
                    const urlGetParams = `&page=${page}&status=${filterSignedStatus}&date=${dateStatus}`
                    console.log(urlGetParams)
                    const url = 'questionnaire/signature-files/signatured-files-by-user' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams

                    const {
                        status,
                        data
                    } = await httpClient.get(url);
                    if (status === 200) {
                        console.log(data.success, "SIGN")
                        console.log(data, "SIGN data")


                        this.pageCount = data.page_count
                        //let signaturedFiles = data.success

                        //let signaturedFilesCount = await dispatch('GET_COUNT_SIGNATURE_FILES', 40)
                        //console.log(signaturedFilesCount, "signaturedFilesCount")
                        for (let i = 0; i < data.success.length; i++) {

                            let signaturedFilesId = data.success[i].file_id
                            //let signaturedFileCount = await dispatch('GET_COUNT_SIGNATURE_FILES', signaturedFileId)

                            data.success[i]['count'] = await this.GET_COUNT_SIGNATURE_FILES(signaturedFilesId);


                        }

                        return data.success

                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_INFO_CERT(file_id) {
                try {

                    const {
                        status,
                        data
                    } = await axios.get('https://signature.uib.kz/infoCert.php?file_id=' + file_id);
                    if (status === 200) {
                        console.log(data, "INFOCERT")
                        return data
                    }
                    return {
                        success: true
                    }


                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_COUNT_SIGNATURE_FILES(file_id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.get('questionnaire/signature-files/count-signatured-files' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&file_id=' + file_id);
                    //this.signatureFilesInfos[0].id
                    if (status === 200) {
                        console.log(data.success, "COUNT SIGN")
                        return data.success
                    }
                    return {
                        success: true
                    }

                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },

        },

        async mounted() {
            this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
            console.log(this.signatureFilesInfos)

        },
    }
</script>

<style scoped>

</style>